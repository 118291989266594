var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"env-view"}},[_c('div',{staticClass:"spot-key"},[_c('span',[_vm._v(_vm._s(_vm.$route.params.key))])]),_c('div',{staticClass:"content-box"},[_c('div',{staticClass:"map-list"},[_c('button',{staticClass:"btn-cctv",on:{"click":_vm.goCctvList}},[_c('img',{attrs:{"src":require("../assets/camera.png")}}),_c('span',[_vm._v("CCTV 整合")])]),_vm._l((_vm.stationInfoList),function(stationInfo){return _c('img',{key:stationInfo.key,staticClass:"preview-dot",class:{ active: stationInfo.key === _vm.$route.params.key },attrs:{"src":require('../assets/' + stationInfo.key + '-dot.png')},on:{"click":function($event){return _vm.goStation(stationInfo.key)}}})})],2),_c('div',{staticClass:"title"},[_vm._v("作業環境監測")]),_c('table',{staticClass:"table"},[_c('thead',[_c('th',[_vm._v(" "+_vm._s(_vm.air4in1.machine_name.substring( _vm.air4in1.machine_name.indexOf('_') + 1 ))+" 點 ")]),_c('th',[_vm._v("數值")]),_c('th',[_vm._v("告知事項")])]),_c('tbody',[_c('tr',[_vm._m(0),_c('td',{class:_vm.setClass(
                'o2',
                _vm.spotInfo.attributes.find((e) => e.key === 'o2').value
              )},[_vm._v(" "+_vm._s(_vm.setRound(_vm.spotInfo.attributes.find((e) => e.key === 'o2').value))+" ")]),_c('td',{class:_vm.setClass(
                'o2',
                _vm.spotInfo.attributes.find((e) => e.key === 'o2').value
              )},[_c('span',{staticClass:"circle"}),_c('span',[_vm._v(_vm._s(_vm.setMemo( 'o2', _vm.spotInfo.attributes.find((e) => e.key === 'o2').value )))])])]),_c('tr',[_c('td',[_vm._v("可燃性氣體LEL")]),_c('td',{class:_vm.setClass(
                'hcl',
                _vm.spotInfo.attributes.find((e) => e.key === 'hcl').value
              )},[_vm._v(" "+_vm._s(_vm.formatValue( _vm.spotInfo.attributes.find((e) => e.key === 'hcl').value ))+" ")]),_c('td',{class:_vm.setClass(
                'hcl',
                _vm.spotInfo.attributes.find((e) => e.key === 'hcl').value
              )},[_c('span',{staticClass:"circle"}),_c('span',[_vm._v(_vm._s(_vm.setMemo( 'hcl', _vm.spotInfo.attributes.find((e) => e.key === 'hcl').value )))])])]),_c('tr',[_vm._m(1),_c('td',{class:_vm.setClass(
                'h2s',
                _vm.spotInfo.attributes.find((e) => e.key === 'h2s').value
              )},[_vm._v(" "+_vm._s(_vm.formatValue( _vm.spotInfo.attributes.find((e) => e.key === 'h2s').value ))+" ")]),_c('td',{class:_vm.setClass(
                'h2s',
                _vm.spotInfo.attributes.find((e) => e.key === 'h2s').value
              )},[_c('span',{staticClass:"circle"}),_c('span',[_vm._v(_vm._s(_vm.setMemo( 'h2s', _vm.spotInfo.attributes.find((e) => e.key === 'h2s').value )))])])]),_c('tr',[_c('td',[_vm._v("一氧化碳CO(ppm)")]),_c('td',{class:_vm.setClass(
                'co',
                _vm.spotInfo.attributes.find((e) => e.key === 'co').value
              )},[_vm._v(" "+_vm._s(_vm.formatValue( _vm.spotInfo.attributes.find((e) => e.key === 'co').value ))+" ")]),_c('td',{class:_vm.setClass(
                'co',
                _vm.spotInfo.attributes.find((e) => e.key === 'co').value
              )},[_c('span',{staticClass:"circle"}),_c('span',[_vm._v(_vm._s(_vm.setMemo( 'co', _vm.spotInfo.attributes.find((e) => e.key === 'co').value )))])])])])]),_vm._m(2)]),_vm._m(3)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',[_vm._v("氧氣O"),_c('span',{staticClass:"small"},[_vm._v("2")]),_vm._v("(%)")])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_vm._v("硫化氫H"),_c('span',{staticClass:"small"},[_vm._v("2")]),_vm._v("S(ppm)")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"memo"},[_c('div',{staticClass:"positive"},[_c('div',{staticClass:"circle"}),_c('span',[_vm._v("建議可施工")])]),_c('div',{staticClass:"negative"},[_c('div',{staticClass:"circle"}),_c('span',[_vm._v("建議不可施工")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo-group"},[_c('img',{attrs:{"src":require("@/assets/futsu-logo.png"),"id":"futsu-logo"}}),_c('img',{attrs:{"src":require("@/assets/lin-logo.png"),"id":"lin-logo"}}),_c('img',{attrs:{"src":require("@/assets/cec-logo.png"),"id":"cec-logo"}})])
}]

export { render, staticRenderFns }