<template>
  <div id="env-view">
    <div class="spot-key">
      <span>{{ $route.params.key }}</span>
    </div>
    <div class="content-box">
      <div class="map-list">
        <button class="btn-cctv" @click="goCctvList">
          <img src="../assets/camera.png" /><span>CCTV 整合</span>
        </button>

        <img
          @click="goStation(stationInfo.key)"
          class="preview-dot"
          :src="require('../assets/' + stationInfo.key + '-dot.png')"
          v-for="stationInfo in stationInfoList"
          :key="stationInfo.key"
          :class="{ active: stationInfo.key === $route.params.key }"
        />
      </div>
      <div class="title">作業環境監測</div>
      <table class="table">
        <thead>
          <th>
            {{
              air4in1.machine_name.substring(
                air4in1.machine_name.indexOf('_') + 1
              )
            }}
            點
          </th>
          <th>數值</th>
          <th>告知事項</th>
        </thead>
        <tbody>
          <tr>
            <td>氧氣O<span class="small">2</span>(%)</td>
            <td
              :class="
                setClass(
                  'o2',
                  spotInfo.attributes.find((e) => e.key === 'o2').value
                )
              "
            >
              {{
                setRound(spotInfo.attributes.find((e) => e.key === 'o2').value)
              }}
            </td>
            <td
              :class="
                setClass(
                  'o2',
                  spotInfo.attributes.find((e) => e.key === 'o2').value
                )
              "
            >
              <span class="circle" /><span>{{
                setMemo(
                  'o2',
                  spotInfo.attributes.find((e) => e.key === 'o2').value
                )
              }}</span>
            </td>
          </tr>
          <tr>
            <td>可燃性氣體LEL</td>
            <td
              :class="
                setClass(
                  'hcl',
                  spotInfo.attributes.find((e) => e.key === 'hcl').value
                )
              "
            >
              {{
                formatValue(
                  spotInfo.attributes.find((e) => e.key === 'hcl').value
                )
              }}
            </td>
            <td
              :class="
                setClass(
                  'hcl',
                  spotInfo.attributes.find((e) => e.key === 'hcl').value
                )
              "
            >
              <span class="circle" /><span>{{
                setMemo(
                  'hcl',
                  spotInfo.attributes.find((e) => e.key === 'hcl').value
                )
              }}</span>
            </td>
          </tr>
          <tr>
            <td>硫化氫H<span class="small">2</span>S(ppm)</td>
            <td
              :class="
                setClass(
                  'h2s',
                  spotInfo.attributes.find((e) => e.key === 'h2s').value
                )
              "
            >
              {{
                formatValue(
                  spotInfo.attributes.find((e) => e.key === 'h2s').value
                )
              }}
            </td>
            <td
              :class="
                setClass(
                  'h2s',
                  spotInfo.attributes.find((e) => e.key === 'h2s').value
                )
              "
            >
              <span class="circle" /><span>{{
                setMemo(
                  'h2s',
                  spotInfo.attributes.find((e) => e.key === 'h2s').value
                )
              }}</span>
            </td>
          </tr>
          <tr>
            <td>一氧化碳CO(ppm)</td>
            <td
              :class="
                setClass(
                  'co',
                  spotInfo.attributes.find((e) => e.key === 'co').value
                )
              "
            >
              {{
                formatValue(
                  spotInfo.attributes.find((e) => e.key === 'co').value
                )
              }}
            </td>
            <td
              :class="
                setClass(
                  'co',
                  spotInfo.attributes.find((e) => e.key === 'co').value
                )
              "
            >
              <span class="circle" /><span>{{
                setMemo(
                  'co',
                  spotInfo.attributes.find((e) => e.key === 'co').value
                )
              }}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="memo">
        <div class="positive">
          <div class="circle"></div>
          <span>建議可施工</span>
        </div>
        <div class="negative">
          <div class="circle"></div>
          <span>建議不可施工</span>
        </div>
      </div>
    </div>
    <div class="logo-group">
      <img src="~@/assets/futsu-logo.png" id="futsu-logo" />
      <img src="~@/assets/lin-logo.png" id="lin-logo" />
      <img src="~@/assets/cec-logo.png" id="cec-logo" />
    </div>
  </div>
</template>

<script>
import { getAir02 } from '@/api/index.js'
import { mapState } from 'vuex'
export default {
  name: 'Env',
  mixins: [],
  data() {
    return {
      dotUrl: '',
      air4in1: null,
      airStandard: {
        o2: 20,
        hcl: 5000,
        h2s: 10000,
        co: 35000,
      },
      spotInfo: null,
    }
  },
  async created() {
    this.dotUrl = require('../assets/' + this.$route.params.key + '-dot.png')
    const id = parseInt(this.$route.params.id)
    this.air4in1 = this.machineInfo.find(
      (e) =>
        e.machine_group === this.$route.params.key.toUpperCase() &&
        e.machine_type === 'air_4in1' &&
        e.id === id
    )
    const aSpotId = this.air4in1.machine_url.substring(
      this.air4in1.machine_url.indexOf('?id=') + 4
    )
    getAir02(aSpotId)
      .then((res) => {
        this.spotInfo = res.data
      })
      .catch((error) => {
        console.log(error)
      })
  },
  computed: {
    ...mapState(['isLogin', 'stationInfoList', 'machineInfo']),
    loading() {
      return false
    },
  },
  methods: {
    setRound(value) {
      return Math.round(value * 10) / 10
    },
    formatValue(value) {
      let tempValue = value * (1 / 10000)
      return tempValue < 1 ? 0 : tempValue
    },
    setMemo(type, value) {
      if (type === 'o2') {
        if (value < this.airStandard[type]) {
          return '建議不可施工'
        } else {
          return '建議可施工'
        }
      } else {
        if (value > this.airStandard[type]) {
          return '建議不可施工'
        } else {
          return '建議可施工'
        }
      }
    },
    setClass(type, value) {
      if (type === 'o2') {
        if (value < this.airStandard[type]) {
          return 'negative'
        } else {
          return 'positive'
        }
      } else {
        if (value > this.airStandard[type]) {
          return 'negative'
        } else {
          return 'positive'
        }
      }
    },
    goCctvList() {
      this.$router.push({
        name: 'CctvList',
        params: { key: this.$route.params.key },
      })
    },
    goStation(key) {
      if (!this.isLogin) return
      console.log(' goStation key', key)
      this.$router.replace({ name: 'Map', params: { key: key } })
    },
  },
}
</script>

<style></style>
